import { Root } from "./src/components/Root";
import { config } from "./src/config/config";

export const wrapRootElement = Root;

console.table({
  ENVIRONMENT: config.ENVIRONMENT,
  SHOP_BACKEND_HOST: config.SHOP_BACKEND_HOST,
  MAILING_HOST: config.MAILING_BACKEND_HOST,
});
