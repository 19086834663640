import React from "react";
import "../styles/index.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { MediaContextProvider } from "./Media";
import "typeface-open-sans";

const queryClient = new QueryClient();

export const Root = ({ element }: any) => {
  return (
    <QueryClientProvider client={queryClient}>
      <MediaContextProvider>{element}</MediaContextProvider>
    </QueryClientProvider>
  );
};
